.lds-dual-ring {
  position: absolute;
  width: 200px;
  height: 200px;
  z-index: 10;
  top: 50%;
  left: 50%;
  margin: -100px 0 0 -50px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid cornflowerblue;
  border-color: cornflowerblue transparent cornflowerblue transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
